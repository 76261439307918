<template>
  <div class="campaigns-table">
    <buyer-campaigns-filter
      :filter.sync="pager.filter"
      @change="pager.page = 1"
    />

    <campaigns-query :pager="pager" class="mt-5">
      <template slot-scope="{ isLoading, data: paginator }">
        <div class="relative">
          <el-table
            :data="paginator && !isLoading ? paginator.data : []"
            border
            class="w-full el-table-slim"
            :default-sort="{ prop: 'start_date', order: 'descending' }"
            :row-class-name="tableRowClassName"
            @sort-change="sortColumns"
          >
            <table-empty-slot
              slot="empty"
              :is-loading="isLoading"
              :has-data="!!paginator"
              :empty-message="emptyFilterMessage"
              error-message="There was a problem loading your campaigns. Please try again later."
            />

            <el-table-column
              label="Status"
              align="center"
              width="100"
              sortable="custom"
              sort-by="status"
            >
              <template slot-scope="{ row }">
                <campaign-mutation>
                  <template
                    slot-scope="{
                      isSaving,
                      cancelCampaign,
                      resumeCampaign,
                      deleteCampaign
                    }"
                  >
                    <campaign-status-column
                      :campaign="row"
                      :loading="isSaving"
                      @cancel="cancelCampaign(row)"
                      @resume="resumeCampaign(row)"
                      @delete="deleteCampaign(row)"
                    />
                  </template>
                </campaign-mutation>
              </template>
            </el-table-column>

            <el-table-column label="IO" align="center" width="100">
              <buyer-io-column
                slot-scope="{ row }"
                :campaign="row"
                :order="row.orders[0]"
                @review="orderToAccept = row.orders[0]"
              />
            </el-table-column>

            <el-table-column label="Campaign" sortable="custom" sort-by="name">
              <template v-slot="{ row }">
                <div class="campaign-ref">{{ row.ref }}</div>
                <div class="campaign-name">{{ row.name }}</div>
              </template>
            </el-table-column>

            <el-table-column
              label="Order Date"
              align="center"
              width="120"
              sortable="custom"
              prop="created_at"
              sort-by="created_at"
            >
              <template slot-scope="{ row }">
                {{ row.created_at | date }}
              </template>
            </el-table-column>

            <el-table-column
              label="Supplier"
              sortable="custom"
              sort-by="orders.supplier.name"
            >
              <div slot-scope="{ row }">
                <supplier-column
                  :supplier="row.orders[0].supplier"
                  :reps="row.orders[0].reps"
                />
              </div>
            </el-table-column>

            <el-table-column
              label="Start Date"
              align="center"
              width="120"
              sortable="custom"
              prop="start_date"
              sort-by="start_date"
            >
              <template slot-scope="{ row }">
                {{ row.start_date | date }}
              </template>
            </el-table-column>

            <el-table-column
              label="End Date"
              align="center"
              width="120"
              sortable="custom"
              prop="end_date"
              sort-by="end_date"
            >
              <template slot-scope="{ row }">
                {{ row.end_date | date }}
              </template>
            </el-table-column>

            <el-table-column label="Payment" width="120">
              <order-payment-column
                slot-scope="{ row }"
                :order="row.orders[0]"
                @review="orderToAccept = $event"
              />
            </el-table-column>

            <el-table-column label="Analytics" align="center" width="235">
              <campaign-analytics-column slot-scope="{ row }" :campaign="row" />
            </el-table-column>
          </el-table>

          <pagination
            v-if="paginator && paginator.paginatorInfo.total > 0"
            :info="paginator.paginatorInfo"
            :pager.sync="pager"
            class="mt-5"
          />
        </div>
      </template>
    </campaigns-query>

    <buyer-acceptance-dialog
      v-if="orderToAccept"
      :order="orderToAccept"
      @close="orderToAccept = false"
    />
  </div>
</template>

<script>
import moment from 'moment';
import { sortFilter } from '@/utils/sortFilter';
import { date as formatDate } from '@/utils/filters';

import { OrderApprovalStatus, OrderStatus } from '@/constants';

import BuyerAcceptanceDialog from '@/modules/Buyer/Campaign/BuyerAcceptanceDialog';
import BuyerCampaignsFilter from '@/modules/Buyer/Campaign/BuyerCampaignsFilter';
import Pagination from '@/components/Core/Table/Pagination';
import TableEmptySlot from '@/components/Core/Table/TableEmptySlot';
import { CampaignMutation } from '@/components/Mutations';
import { CampaignsQuery } from '@/components/Queries';

import {
  BuyerIoColumn,
  CampaignAnalyticsColumn,
  CampaignStatusColumn,
  OrderPaymentColumn,
  SupplierColumn
} from '@/components/Core/Table/Columns';

export default {
  components: {
    BuyerAcceptanceDialog,
    BuyerCampaignsFilter,
    BuyerIoColumn,
    CampaignAnalyticsColumn,
    CampaignMutation,
    CampaignsQuery,
    CampaignStatusColumn,
    OrderPaymentColumn,
    Pagination,
    SupplierColumn,
    TableEmptySlot
  },

  data() {
    return {
      isExporting: false,
      orderToAccept: false,
      pager: {
        perPage: 10,
        page: 1,
        sort: [{ column: 'start_date', order: 'descending' }],
        filter: {
          start_date: this.filterDateBetween(moment().startOf('month'), null),
          status: []
        },
        update: 0
      }
    };
  },

  computed: {
    emptyFilterMessage() {
      if (this.pager.filter.start_date) {
        let startDate = this.pager.filter.start_date['>='];
        let endDate = this.pager.filter.start_date['<='];

        let str =
          "You don't have any campaigns running between " +
          formatDate(startDate);

        if (endDate) {
          str += ' and ' + formatDate(endDate);
        } else {
          str += ' and the end of time';
        }

        return str;
      } else {
        return "You don't have any campaigns :(";
      }
    }
  },

  methods: {
    ...sortFilter,

    tableRowClassName({ row: campaign }) {
      let order = campaign.orders[0];

      if (order.status !== OrderStatus.CANCELED) {
        if (order.approval_status === OrderApprovalStatus.CHANGES_REQUESTED) {
          return 'row-error';
        } else if (order.approval_status === OrderApprovalStatus.PENDING) {
          return 'row-warning';
        }
      }

      return '';
    }
  }
};
</script>
