<template>
  <div class="buyer-ads-filter flex flex-wrap">
    <div class="flex-shrink w-auto">
      <date-range-filter
        v-model="runDate"
        class="w-auto"
        @change="change({ start_date: $event })"
      />
    </div>
    <div class="text-right overflow-scroll-x flex-grow mt-0">
      <buyer-ads-filter-data-query>
        <template slot-scope="{ data: buyer }">
          <div v-if="buyer" class="flex flex-wrap justify-end">
            <div class="flex-shrink">
              <product-tree-select
                :products="buyer.purchased_products"
                @change="change({ 'orderProduct.medium_name': $event })"
              />
            </div>
            <div class="flex-shrink ml-3">
              <custom-el-select
                v-model="statuses"
                :options="AdStatuses"
                @change="change({ status: $event })"
              />
            </div>
            <div class="flex-shrink ml-3">
              <export-button :is-exporting="isExporting" @click="exportAds" />
            </div>
          </div>
        </template>
      </buyer-ads-filter-data-query>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { download } from '@/utils/helpers';
import { AdStatusInfo } from '@/constants';

import DateRangeFilter from '@/components/Core/Table/Filters/DateRangeFilter';
import ExportButton from '@/components/Core/Table/ExportButton';
import ProductTreeSelect from '@/components/Core/ProductTreeSelect';
import CustomElSelect from '@/components/Custom/CustomElSelect';
import { BuyerAdsFilterDataQuery } from '@/components/Queries';

export default {
  components: {
    BuyerAdsFilterDataQuery,
    DateRangeFilter,
    ExportButton,
    ProductTreeSelect,
    CustomElSelect
  },
  props: {
    filter: { type: Object, default: null }
  },

  data() {
    let minDate = this.filter.start_date ? this.filter.start_date['>='] : '';
    let maxDate = this.filter.start_date ? this.filter.start_date['<='] : '';

    return {
      AdStatuses: Object.values(AdStatusInfo),
      runDate: [minDate, maxDate],
      statuses: [],
      isExporting: false
    };
  },

  methods: {
    change(change) {
      let filter = { ...this.filter, ...change };
      this.$emit('update:filter', filter);
      this.$emit('change', filter);
    },

    exportAds() {
      let params = {
        filter: this.filter
      };

      this.isExporting = true;

      axios
        .post(
          '/buyer/' + this.$store.getters['auth/buyerId'] + '/ads/export',
          params
        )
        .then(({ data }) => {
          this.isExporting = false;

          if (data.success) {
            download(data.csv, data.name);
          }
        });
    }
  }
};
</script>
