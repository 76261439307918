<template>
  <div class="w-full">
    <h2>Organization Name</h2>
    <div class="text-md mt-2">
      <slot name="description">
        <div>
          This should be the name of your media organization. It may be the same
          name as a specific publication, or the name of an organization which
          oversees multiple media outlets.
        </div>
      </slot>
      <el-form
        ref="form"
        class="mt-4"
        :disabled="isLoading || isDisabled"
        :rules="rules"
        :model="input"
      >
        <el-form-item prop="organizationName">
          <el-input
            v-model="input.organizationName"
            placeholder="Organization Name"
          />
        </el-form-item>
      </el-form>
      <el-button
        v-if="!isLoading"
        :loading="!hasChanges || isDisabled"
        class="w-full button-blue mt-8"
        @click="onSubmit"
      >
        Save Changes
      </el-button>
      <loading-button v-else class="w-full mt-8" />
    </div>
  </div>
</template>

<script>
import { LoadingButton } from '@/components/Core/Loading';

export default {
  components: {
    LoadingButton
  },
  props: {
    isDisabled: Boolean,
    isLoading: Boolean,
    organization: { type: String, default: '' }
  },
  data() {
    return {
      input: {
        organizationName: this.organization
      },
      rules: {
        organizationName: [
          { required: true, message: 'Please input a name', trigger: 'blur' }
        ]
      }
    };
  },
  computed: {
    hasChanges() {
      return this.input.organizationName !== this.organization;
    }
  },
  methods: {
    onSubmit() {
      this.$refs.form.clearValidate();

      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit('submit', this.input.organizationName);
        }
      });
    }
  }
};
</script>
