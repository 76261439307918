<template>
  <div id="page-buyer-dashboard" class="page-content text-dark-gray">
    <the-page-header>
      <template #title>
        Campaigns
      </template>
      <template #subtitle>
        Use this page to view and manage all ad campaigns
      </template>
    </the-page-header>

    <el-tabs
      :value="ordersTab"
      @tab-click="$router.push({ name: $event.name })"
    >
      <el-tab-pane disabled label="View by:" />
      <el-tab-pane name="buyer.dashboard.ads" label="Run Date" />
      <el-tab-pane name="buyer.dashboard.campaigns" label="Campaign" />
    </el-tabs>

    <transition name="fade" mode="out-in">
      <router-view class="mt-2" />
    </transition>
  </div>
</template>

<script>
import ThePageHeader from '@/components/Core/Layout/ThePageHeader';

export default {
  components: {
    ThePageHeader
  },

  data() {
    return {
      activeTab: 'local',
      ordersTab: this.$route.name
    };
  }
};
</script>
