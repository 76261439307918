<template>
  <div class="date-range-filter inline-block">
    <el-date-picker
      type="daterange"
      :value="value"
      range-separator="-"
      start-placeholder="Start date"
      end-placeholder="End date"
      format="MM/dd/yyyy"
      class="date-range-input"
      :picker-options="pickerOptions"
      @input="$emit('input', $event)"
      @change="change"
    />
  </div>
</template>

<script>
import { sortFilter } from '@/utils/sortFilter';
import { PICKER_SHORTCUTS } from '@/utils/date';

export default {
  props: {
    value: {
      type: [Date, Array],
      default: null
    }
  },

  data() {
    return {
      pickerOptions: {
        shortcuts: PICKER_SHORTCUTS.ALL
      }
    };
  },

  methods: {
    ...sortFilter,

    change(dateRange) {
      if (dateRange) {
        this.$emit('change', this.filterDateBetween(...dateRange));
      } else {
        this.$emit('change', this.filterDateBetween(null, null));
      }
    }
  }
};
</script>
