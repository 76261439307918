<template>
  <div class="buyer-ads">
    <buyer-ads-filter :filter.sync="pager.filter" @change="pager.page = 1" />

    <buyer-ads-query :pager="pager">
      <template slot-scope="{ isLoading, data: paginator }">
        <div class="relative">
          <el-table
            :data="!isLoading && paginator ? paginator.data : []"
            border
            class="w-full mt-6 el-table-slim"
            :default-sort="{ prop: 'start_date', order: 'descending' }"
            @sort-change="sortColumns"
          >
            <table-empty-slot
              slot="empty"
              :is-loading="isLoading"
              :has-data="!!paginator"
              :empty-message="emptyFilterMessage"
              error-message="There was a problem loading your ads. Please try again later."
            />

            <el-table-column
              label="Status"
              align="center"
              width="100"
              sortable="custom"
              sort-by="status"
              >]
              <status-icon
                slot-scope="{ row }"
                :icons="AdStatusInfo"
                :status="row.status"
              />
            </el-table-column>

            <el-table-column
              label="Run Date"
              align="center"
              width="120"
              prop="start_date"
              sortable="custom"
              sort-by="start_date"
            >
              <div slot-scope="{ row }">
                {{ row.start_date | date }}
              </div>
            </el-table-column>

            <el-table-column label="IO" align="center" width="100">
              <buyer-io-column
                slot-scope="{ row }"
                :order="row.order"
                @review="setOrderToAccept(row)"
              />
            </el-table-column>

            <el-table-column
              label="Campus"
              sortable="custom"
              sort-by="campus.name"
            >
              <div slot-scope="{ row }">
                <supplier-column
                  :campus="row.campus"
                  :supplier="row.supplier"
                  :reps="row.order.reps"
                />
              </div>
            </el-table-column>

            <el-table-column label="Ad Details">
              <ad-column slot-scope="{ row }" :ad="row" />
            </el-table-column>

            <el-table-column width="140" label="Fulfillment Due">
              <fulfillment-due-column slot-scope="{ row }" :ad="row" />
            </el-table-column>

            <el-table-column label="Fulfillment" align="center" width="220">
              <creative-column
                slot-scope="{ row }"
                :is-disabled="!$can('buyer_manage_creative')"
                :ad="row"
              />
            </el-table-column>
          </el-table>

          <pagination
            v-if="paginator && paginator.paginatorInfo.total > 0"
            :info="paginator.paginatorInfo"
            :pager.sync="pager"
            class="mt-5"
          />
        </div>
      </template>
    </buyer-ads-query>

    <buyer-acceptance-dialog
      v-if="orderToAccept"
      :order="orderToAccept"
      @close="orderToAccept = false"
    />
  </div>
</template>

<script>
import moment from 'moment';
import { sortFilter } from '@/utils/sortFilter';
import { filePdf } from '@/vendor/icons';
import { AccountType, AdStatus, AdStatusInfo } from '@/constants';
import { date as formatDate } from '@/utils/filters';

import BuyerAcceptanceDialog from '@/modules/Buyer/Campaign/BuyerAcceptanceDialog';
import BuyerAdsFilter from '@/modules/Buyer/Ads/BuyerAdsFilter';
import Pagination from '@/components/Core/Table/Pagination';
import StatusIcon from '@/components/Core/Statuses/StatusIcon';
import TableEmptySlot from '@/components/Core/Table/TableEmptySlot';
import { BuyerAdsQuery } from '@/components/Queries';

import {
  AdColumn,
  BuyerIoColumn,
  CreativeColumn,
  FulfillmentDueColumn,
  SupplierColumn
} from '@/components/Core/Table/Columns';

export default {
  components: {
    AdColumn,
    BuyerAcceptanceDialog,
    BuyerAdsFilter,
    BuyerAdsQuery,
    BuyerIoColumn,
    CreativeColumn,
    FulfillmentDueColumn,
    Pagination,
    SupplierColumn,
    StatusIcon,
    TableEmptySlot
  },

  data() {
    return {
      AccountType,
      AdStatus,
      AdStatusInfo,
      orderToAccept: false,
      pager: {
        perPage: 10,
        page: 1,
        sort: [{ column: 'start_date', order: 'descending' }],
        filter: {
          start_date: this.filterDateBetween(moment(), null)
        }
      },

      // Icons
      filePdf
    };
  },

  computed: {
    emptyFilterMessage() {
      if (this.pager.filter.start_date) {
        let startDate = this.pager.filter.start_date['>='];
        let endDate = this.pager.filter.start_date['<='];

        let str =
          "You don't have any ads running between " + formatDate(startDate);

        if (endDate) {
          str += ' and ' + formatDate(endDate);
        } else {
          str += ' and the end of time';
        }

        return str;
      } else {
        return "You don't have any ads :(";
      }
    }
  },

  methods: {
    ...sortFilter,

    setOrderToAccept(ad) {
      this.orderToAccept = ad.order;

      // XXX: since we already have the supplier loaded, we don't want to have to load it again nested in an order
      // There is probably a way to query a cached supplier w/ Apollo, and should replace this with that
      this.orderToAccept.supplier = ad.supplier;
    }
  }
};
</script>
