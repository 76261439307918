<template>
  <section>
    <div class="flex justify-between">
      <div>
        <h2>Team Members</h2>
        <div class="text-md mt-2">
          Add and manage members of your team.
        </div>
      </div>
      <el-button
        class="button-blue h-11"
        :disabled="!$can('buyer_manage_team_members')"
        @click="onOpenEditDialog(null, 'INVITE')"
      >
        Invite User
      </el-button>
    </div>
    <buyer-team-query :invites-filter="invitesFilter" @data="buyer = $event">
      <template v-slot="{ isLoading }">
        <buyer-team-mutation @data="onData">
          <template
            v-slot="{
              loading,
              sendBuyerMemberInvite,
              rejectBuyerMemberRequest,
              setBuyerMemberRole,
              setBuyerContacts,
              removeBuyerMember
            }"
          >
            <pending-team-table
              class="mt-8"
              :is-loading="isLoading"
              :team="buyer && buyer.invites"
              @invite="onOpenEditDialog(null, 'INVITE')"
              @add="onOpenEditDialog($event, 'ADD')"
              @reject="onOpenRemoveDialog($event, 'Reject')"
            />
            <current-team-table
              class="mt-8"
              :team="buyer && buyer.teamMembers"
              :primary-contact="buyer && buyer.primaryContact"
              :billing-contact="buyer && buyer.billingContact"
              :optional-contacts="buyer && buyer.creativeContacts"
              :is-loading="isLoading"
              @invite="onOpenEditDialog(null, 'INVITE')"
              @edit="onOpenEditDialog($event, 'EDIT')"
              @remove="onOpenRemoveDialog($event, 'Remove')"
            />

            <edit-team-member-dialog
              :user="selectedUser"
              :user-contact-types="userContactTypes"
              :optional-contact-type="ContactType.CREATIVE"
              :action="dialogType"
              :visible="editMemberDialogVisible"
              :is-loading="loading"
              :role-options="roleOptions"
              @submit="
                onSubmitEdit(
                  $event,
                  sendBuyerMemberInvite,
                  setBuyerMemberRole,
                  setBuyerContacts
                )
              "
              @close="editMemberDialogVisible = false"
            />
            <remove-team-member-dialog
              :is-loading="loading"
              :title="removeDialogTitle"
              :visible="removeMemberDialogVisible"
              @submit="
                onSubmitRemove(rejectBuyerMemberRequest, removeBuyerMember)
              "
              @close="removeMemberDialogVisible = false"
            />
          </template>
        </buyer-team-mutation>
      </template>
    </buyer-team-query>
  </section>
</template>

<script>
import CurrentTeamTable from '@/components/Core/Settings/CurrentTeamTable';
import EditTeamMemberDialog from '@/components/Core/Settings/EditTeamMemberDialog';
import RemoveTeamMemberDialog from '@/components/Core/Settings/RemoveTeamMemberDialog';
import PendingTeamTable from '@/components/Core/Settings/PendingTeamTable';
import { BuyerTeamMutation } from '@/components/Mutations';
import { BuyerTeamQuery } from '@/components/Queries';
import { BuyerRoleSelections, ContactType, InviteStatus } from '@/constants';
import { xor } from 'lodash';

export default {
  components: {
    CurrentTeamTable,
    EditTeamMemberDialog,
    RemoveTeamMemberDialog,
    PendingTeamTable,
    BuyerTeamMutation,
    BuyerTeamQuery
  },
  data() {
    return {
      dialogType: 'INVITE',
      ContactType,
      removeDialogTitle: undefined,
      editMemberDialogVisible: false,
      removeMemberDialogVisible: false,
      isMutatingMember: false,
      invitesFilter: {
        status: {
          '!=': [InviteStatus.REJECTED, InviteStatus.ACCEPTED]
        }
      },
      buyer: null,
      selectedUser: null
    };
  },
  computed: {
    roleOptions() {
      return BuyerRoleSelections;
    },
    userContactTypes() {
      let types = [];
      if (!this.buyer || !this.selectedUser) return [];
      if (this.buyer.primaryContact.user_id === this.selectedUser.id) {
        types.push(ContactType.PRIMARY);
      }
      if (
        this.buyer.billingContact &&
        this.buyer.billingContact.user_id === this.selectedUser.id
      ) {
        types.push(ContactType.BILLING);
      }
      if (
        this.buyer.creativeContacts &&
        this.buyer.creativeContacts.find(
          contact => contact.user_id === this.selectedUser.id
        )
      ) {
        types.push(ContactType.CREATIVE);
      }
      return types;
    }
  },
  methods: {
    onOpenEditDialog(user, dialogType) {
      this.dialogType = dialogType;
      this.selectedUser = user;
      this.editMemberDialogVisible = true;
    },
    onOpenRemoveDialog(user, dialogTitle) {
      this.removeDialogTitle = dialogTitle;
      this.selectedUser = user;
      this.removeMemberDialogVisible = true;
    },
    onData() {
      this.selectedUser = null;
      this.editMemberDialogVisible = false;
      this.removeMemberDialogVisible = false;
    },
    onSubmitEdit(
      form,
      sendbuyerMemberInvite,
      setbuyerMemberRole,
      setbuyerContacts
    ) {
      if (this.dialogType === 'EDIT') {
        const contactsInput = xor(form.contacts, this.userContactTypes).map(
          contactType => ({
            contactType,
            addContact: form.contacts.includes(contactType)
          })
        );
        setbuyerContacts(this.selectedUser.id, contactsInput);
        if (form.role) {
          setbuyerMemberRole(this.selectedUser.id, form.role);
        }
      } else {
        sendbuyerMemberInvite({
          email: form.email,
          role: form.role,
          contactType: form.contacts.includes(ContactType.CREATIVE)
            ? 'creative'
            : null
        });
      }
    },
    onSubmitRemove(rejectBuyerMemberRequest, removebuyerMember) {
      if (this.selectedUser.__typename === 'Invite') {
        rejectBuyerMemberRequest(this.selectedUser.id);
      } else {
        removebuyerMember(this.selectedUser.id);
      }
    }
  }
};
</script>
