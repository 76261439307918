<template>
  <buyer-mutation @error="onError" @data="onData">
    <template v-slot="{ isSaving, updateBuyerAccount }">
      <edit-organization-name
        ref="editOrganizationName"
        :is-disabled="!$can('buyer_edit_organization_info')"
        :organization="buyerInfo.buyer_company"
        :is-loading="isUpdatingOrganization"
        class="border-bottom pb-8"
        @submit="onEditOrganizationName($event, updateBuyerAccount)"
      >
        <template #description>
          This is the name of your company or business.
        </template>
      </edit-organization-name>
      <edit-address
        title="Primary Address"
        sub-title="Please enter your primary mailing address"
        class="mt-5 border-top pt-5"
        :address="buyerInfo.primaryAddress"
        :is-loading="isSaving"
        :is-editing="$can('buyer_edit_organization_info')"
        hide-close
        @submit="
          onEditBuyerInfo(
            {
              primaryAddress: $event
            },
            updateBuyerAccount
          )
        "
      />
      <edit-address
        title="Billing Address"
        sub-title="Please enter your billing address"
        class="mt-5 border-top pt-5"
        :address="buyerInfo.billingAddress"
        :is-loading="isSaving"
        is-editing
        hide-close
        @submit="
          onEditBuyerInfo(
            {
              billingAddress: $event
            },
            updateBuyerAccount
          )
        "
      />
    </template>
  </buyer-mutation>
</template>

<script>
import EditOrganizationName from '@/components/Core/Settings/EditOrganizationName';
import { BuyerMutation } from '@/components/Mutations';
import EditAddress from '@/components/Supplier/Customers/EditAddress';

export default {
  components: {
    EditAddress,
    BuyerMutation,
    EditOrganizationName
  },
  data() {
    return {
      isUpdatingOrganization: false,
      isEditingPrimaryAddress: false
    };
  },
  computed: {
    buyerInfo() {
      return this.$store.getters['auth/buyer'];
    }
  },
  methods: {
    onError() {
      if (this.isUpdatingOrganization) {
        this.isUpdatingOrganization = false;
        this.$refs.editOrganizationName.resetForm();
      }
    },
    onData() {
      this.$store
        .dispatch('auth/getUser')
        .then(() => {
          this.$message.success('Your information was successfully updated!');
        })
        .finally(() => {
          this.isUpdatingOrganization = false;
        });
    },
    onEditOrganizationName(company, updateBuyerAccount) {
      this.isUpdatingOrganization = true;
      updateBuyerAccount(this.$store.getters['auth/buyerId'], {
        buyer_company: company
      });
    },
    onEditBuyerInfo(businessInfo, updateBuyerAccount) {
      updateBuyerAccount(this.$store.getters['auth/buyerId'], businessInfo);
    }
  }
};
</script>
