<template>
  <el-dialog
    title="Review"
    visible
    append-to-body
    modal-append-to-body
    :close-on-click-modal="false"
    class="buyer-acceptance-dialog"
    @update:visible="$event || $emit('close')"
  >
    <div class="p-3">
      <h2>Campaign Updates</h2>

      <div class="leading-loose">
        The publisher has made changes to your campaign below. Review and accept
        changes to activate campaign
      </div>

      <div v-if="isRateClassChanged" class="card w-full p-6 mt-7">
        {{ order.supplier.display_name }} has changed your rate class from
        {{ rateClassName }} to <b>{{ approvedRateClassName }}</b
        >.
      </div>

      <div v-if="isPaymentMethodChanged" class="card w-full p-6 mt-7">
        <div>
          {{ order.supplier.display_name }} has changed your payment type from
          {{ order.paymentMethod.name }} to
          <b>{{ order.approvedPaymentMethod.name }}</b
          >.
          <el-tooltip effect="light">
            <div slot="content">
              <template v-if="isPrepaid">
                Credit cards are charged in monthly installments. You will be
                charged on the first of the month for all ads running in the
                upcoming month.
              </template>
              <template v-else>
                Credit cards are charged in monthly installments. You will be
                charged on the first of the month for all ads run in the
                previous month
              </template>
            </div>

            <a>When will I be charged?</a>
          </el-tooltip>
        </div>

        <div class="my-8">
          <div class="ml-4 border-l-5 border-reset pl-5">
            <form-credit-payment ref="formCreditPayment" />
          </div>
        </div>

        <el-alert
          v-if="formError"
          type="error"
          :title="formError"
          class="mt-4"
          :closable="false"
        />
      </div>

      <div class="card w-full p-5 mt-7">
        <template v-if="isPrepaid">
          {{ order.supplier.name }} requires you to pre pay for this campaign.
          Your first payment will be due before your first ad runs on
          <b>{{ order.start_date | date }}</b
          >.
        </template>
        <template v-else>
          {{ order.supplier.name }} has approved you to post pay for this
          campaign.
        </template>
      </div>
    </div>

    <template slot="footer">
      <template v-if="!isSaving">
        <el-button @click="$emit('close')">Cancel</el-button>
        <buyer-orders-mutation>
          <el-button
            slot-scope="{ acceptChanges }"
            class="button-blue"
            @click="submit(acceptChanges)"
          >
            Accept Changes
          </el-button>
        </buyer-orders-mutation>
      </template>
      <loading-button v-else />
    </template>
  </el-dialog>
</template>

<script>
import { BillingPreference, RateClassLabel } from '@/constants';

import FormCreditPayment from '@/components/AdShop/Cart/FormCreditPayment';
import LoadingButton from '@/components/Core/Loading/LoadingButton';
import { BuyerOrdersMutation } from '@/components/Mutations';

export default {
  components: {
    BuyerOrdersMutation,
    FormCreditPayment,
    LoadingButton
  },
  props: {
    order: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isSaving: false,
      formError: false
    };
  },

  computed: {
    isPrepaid() {
      return this.order.billing_preference === BillingPreference.PREPAID;
    },

    rateClassName() {
      return RateClassLabel[this.order.rate_class];
    },

    approvedRateClassName() {
      return RateClassLabel[this.order.approved_rate_class];
    },

    isRateClassChanged() {
      return this.order.rate_class !== this.order.approved_rate_class;
    },

    isPaymentMethodChanged() {
      return (
        this.order.paymentMethod.id !== this.order.approvedPaymentMethod.id
      );
    }
  },

  methods: {
    async submit(acceptChanges) {
      this.isSaving = true;
      this.formError = false;

      let form = {};

      if (this.isPaymentMethodChanged) {
        const result = await this.$refs.formCreditPayment.submit();

        if (result.error) {
          this.formError = result.error;
          return;
        } else {
          form.stripeToken = result.stripeToken;
          form.billingAddress = result.form;
        }
      }

      try {
        await acceptChanges(this.order, form);
        this.$emit('close');
      } catch (e) {
        this.formError = e.message;
      }

      this.isSaving = false;
    }
  }
};
</script>
