var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"flex justify-between"},[_vm._m(0),_c('el-button',{staticClass:"button-blue h-11",attrs:{"disabled":!_vm.$can('buyer_manage_team_members')},on:{"click":function($event){return _vm.onOpenEditDialog(null, 'INVITE')}}},[_vm._v(" Invite User ")])],1),_c('buyer-team-query',{attrs:{"invites-filter":_vm.invitesFilter},on:{"data":function($event){_vm.buyer = $event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('buyer-team-mutation',{on:{"data":_vm.onData},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var loading = ref.loading;
          var sendBuyerMemberInvite = ref.sendBuyerMemberInvite;
          var rejectBuyerMemberRequest = ref.rejectBuyerMemberRequest;
          var setBuyerMemberRole = ref.setBuyerMemberRole;
          var setBuyerContacts = ref.setBuyerContacts;
          var removeBuyerMember = ref.removeBuyerMember;
return [_c('pending-team-table',{staticClass:"mt-8",attrs:{"is-loading":isLoading,"team":_vm.buyer && _vm.buyer.invites},on:{"invite":function($event){return _vm.onOpenEditDialog(null, 'INVITE')},"add":function($event){return _vm.onOpenEditDialog($event, 'ADD')},"reject":function($event){return _vm.onOpenRemoveDialog($event, 'Reject')}}}),_c('current-team-table',{staticClass:"mt-8",attrs:{"team":_vm.buyer && _vm.buyer.teamMembers,"primary-contact":_vm.buyer && _vm.buyer.primaryContact,"billing-contact":_vm.buyer && _vm.buyer.billingContact,"optional-contacts":_vm.buyer && _vm.buyer.creativeContacts,"is-loading":isLoading},on:{"invite":function($event){return _vm.onOpenEditDialog(null, 'INVITE')},"edit":function($event){return _vm.onOpenEditDialog($event, 'EDIT')},"remove":function($event){return _vm.onOpenRemoveDialog($event, 'Remove')}}}),_c('edit-team-member-dialog',{attrs:{"user":_vm.selectedUser,"user-contact-types":_vm.userContactTypes,"optional-contact-type":_vm.ContactType.CREATIVE,"action":_vm.dialogType,"visible":_vm.editMemberDialogVisible,"is-loading":loading,"role-options":_vm.roleOptions},on:{"submit":function($event){return _vm.onSubmitEdit(
                $event,
                sendBuyerMemberInvite,
                setBuyerMemberRole,
                setBuyerContacts
              )},"close":function($event){_vm.editMemberDialogVisible = false}}}),_c('remove-team-member-dialog',{attrs:{"is-loading":loading,"title":_vm.removeDialogTitle,"visible":_vm.removeMemberDialogVisible},on:{"submit":function($event){return _vm.onSubmitRemove(rejectBuyerMemberRequest, removeBuyerMember)},"close":function($event){_vm.removeMemberDialogVisible = false}}})]}}],null,true)})]}}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',[_vm._v("Team Members")]),_c('div',{staticClass:"text-md mt-2"},[_vm._v(" Add and manage members of your team. ")])])}]

export { render, staticRenderFns }