var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"campaigns-table"},[_c('buyer-campaigns-filter',{attrs:{"filter":_vm.pager.filter},on:{"update:filter":function($event){return _vm.$set(_vm.pager, "filter", $event)},"change":function($event){_vm.pager.page = 1}}}),_c('campaigns-query',{staticClass:"mt-5",attrs:{"pager":_vm.pager},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
var paginator = ref.data;
return [_c('div',{staticClass:"relative"},[_c('el-table',{staticClass:"w-full el-table-slim",attrs:{"data":paginator && !isLoading ? paginator.data : [],"border":"","default-sort":{ prop: 'start_date', order: 'descending' },"row-class-name":_vm.tableRowClassName},on:{"sort-change":_vm.sortColumns}},[_c('table-empty-slot',{attrs:{"slot":"empty","is-loading":isLoading,"has-data":!!paginator,"empty-message":_vm.emptyFilterMessage,"error-message":"There was a problem loading your campaigns. Please try again later."},slot:"empty"}),_c('el-table-column',{attrs:{"label":"Status","align":"center","width":"100","sortable":"custom","sort-by":"status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('campaign-mutation',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var isSaving = ref.isSaving;
                  var cancelCampaign = ref.cancelCampaign;
                  var resumeCampaign = ref.resumeCampaign;
                  var deleteCampaign = ref.deleteCampaign;
return [_c('campaign-status-column',{attrs:{"campaign":row,"loading":isSaving},on:{"cancel":function($event){return cancelCampaign(row)},"resume":function($event){return resumeCampaign(row)},"delete":function($event){return deleteCampaign(row)}}})]}}],null,true)})]}}],null,true)}),_c('el-table-column',{attrs:{"label":"IO","align":"center","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
return _c('buyer-io-column',{attrs:{"campaign":row,"order":row.orders[0]},on:{"review":function($event){_vm.orderToAccept = row.orders[0]}}})}}],null,true)}),_c('el-table-column',{attrs:{"label":"Campaign","sortable":"custom","sort-by":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
return [_c('div',{staticClass:"campaign-ref"},[_vm._v(_vm._s(row.ref))]),_c('div',{staticClass:"campaign-name"},[_vm._v(_vm._s(row.name))])]}}],null,true)}),_c('el-table-column',{attrs:{"label":"Order Date","align":"center","width":"120","sortable":"custom","prop":"created_at","sort-by":"created_at"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("date")(row.created_at))+" ")]}}],null,true)}),_c('el-table-column',{attrs:{"label":"Supplier","sortable":"custom","sort-by":"orders.supplier.name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
return _c('div',{},[_c('supplier-column',{attrs:{"supplier":row.orders[0].supplier,"reps":row.orders[0].reps}})],1)}}],null,true)}),_c('el-table-column',{attrs:{"label":"Start Date","align":"center","width":"120","sortable":"custom","prop":"start_date","sort-by":"start_date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("date")(row.start_date))+" ")]}}],null,true)}),_c('el-table-column',{attrs:{"label":"End Date","align":"center","width":"120","sortable":"custom","prop":"end_date","sort-by":"end_date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("date")(row.end_date))+" ")]}}],null,true)}),_c('el-table-column',{attrs:{"label":"Payment","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
return _c('order-payment-column',{attrs:{"order":row.orders[0]},on:{"review":function($event){_vm.orderToAccept = $event}}})}}],null,true)}),_c('el-table-column',{attrs:{"label":"Analytics","align":"center","width":"235"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
return _c('campaign-analytics-column',{attrs:{"campaign":row}})}}],null,true)})],1),(paginator && paginator.paginatorInfo.total > 0)?_c('pagination',{staticClass:"mt-5",attrs:{"info":paginator.paginatorInfo,"pager":_vm.pager},on:{"update:pager":function($event){_vm.pager=$event}}}):_vm._e()],1)]}}])}),(_vm.orderToAccept)?_c('buyer-acceptance-dialog',{attrs:{"order":_vm.orderToAccept},on:{"close":function($event){_vm.orderToAccept = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }