<template>
  <el-container id="the-layout-buyer">
    <the-default-layout>
      <the-buyer-account-bar slot="header">
        <span slot="title">buyer</span>
      </the-buyer-account-bar>

      <the-nav-bar
        v-if="canViewNavbar"
        slot="sidebar"
        :nav="navMenu"
        theme="light"
      />
    </the-default-layout>
  </el-container>
</template>

<script>
import TheDefaultLayout from '@/components/Core/Layout/TheDefaultLayout';
import TheBuyerAccountBar from '@/modules/Buyer/TheBuyerAccountBar';
import adminMenu from '@/navigation/admin';
import buyerMenu from '@/navigation/buyer';
import TheNavBar from '@/components/Core/Layout/TheNavBar';
import { userCircleO } from '@/vendor/icons';

export default {
  name: 'TheBuyerLayout',
  components: {
    TheDefaultLayout,
    TheBuyerAccountBar,
    TheNavBar
  },
  data() {
    return {
      currentNav: buyerMenu,
      canViewNavbar: true,
      userCircleO
    };
  },
  computed: {
    navMenu() {
      return this.currentNav(this.$store, this.$router);
    },
    accountName() {
      return (
        this.$store.getters['auth/name'] || this.$store.getters['auth/email']
      );
    }
  },
  created() {
    // Toggle between Super Admin menu and Buyer Menu if user has permission
    if (this.$can('do_anything')) {
      this.$onKeyEvent('toggle-admin-nav', () => {
        this.currentNav = this.currentNav === buyerMenu ? adminMenu : buyerMenu;
        this.canViewNavbar =
          this.currentNav === adminMenu ||
          this.$store.getters['auth/isLocalBuyer'];
      });
    }
  }
};
</script>
