var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('buyer-mutation',{on:{"error":_vm.onError,"data":_vm.onData},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isSaving = ref.isSaving;
var updateBuyerAccount = ref.updateBuyerAccount;
return [_c('edit-organization-name',{ref:"editOrganizationName",staticClass:"border-bottom pb-8",attrs:{"is-disabled":!_vm.$can('buyer_edit_organization_info'),"organization":_vm.buyerInfo.buyer_company,"is-loading":_vm.isUpdatingOrganization},on:{"submit":function($event){return _vm.onEditOrganizationName($event, updateBuyerAccount)}},scopedSlots:_vm._u([{key:"description",fn:function(){return [_vm._v(" This is the name of your company or business. ")]},proxy:true}],null,true)}),_c('edit-address',{staticClass:"mt-5 border-top pt-5",attrs:{"title":"Primary Address","sub-title":"Please enter your primary mailing address","address":_vm.buyerInfo.primaryAddress,"is-loading":isSaving,"is-editing":_vm.$can('buyer_edit_organization_info'),"hide-close":""},on:{"submit":function($event){return _vm.onEditBuyerInfo(
          {
            primaryAddress: $event
          },
          updateBuyerAccount
        )}}}),_c('edit-address',{staticClass:"mt-5 border-top pt-5",attrs:{"title":"Billing Address","sub-title":"Please enter your billing address","address":_vm.buyerInfo.billingAddress,"is-loading":isSaving,"is-editing":"","hide-close":""},on:{"submit":function($event){return _vm.onEditBuyerInfo(
          {
            billingAddress: $event
          },
          updateBuyerAccount
        )}}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }